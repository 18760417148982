<template>
  <div class="pr full-h full-w wrap">
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="getData">
        <div style="padding-bottom:8px" v-if="list.length">
          <div class="chat-item"
            v-for="(item, index) in list" :key="index"
            @click="toImRoom(item)">
            <div class="avatar">
              <img style="width: 40px; height: 40px" :src="item.doctorPic"/>
              <span v-if="item.unreadCount && item.unreadCount !== 0">{{ item.unreadCount }}</span>
            </div>
            <div class="chat-con">
              <div class="con-top">
                <div style="flex: 1;display: flex;align-items: center;">
                  <span class="name">{{item.doctorName}}</span>
                  <span class="title">{{item.technicalName}}</span>
                  <div
                    class="tag"
                    :class="item.orderType === 1 ? 'type1': 'type2'">
                    {{item.orderType === 1? "诊后随访": "在线问诊" }}
                  </div>
                </div>

                <div
                  class="state"
                  :style="{color: countColor(item.orderType,item.state)}">
                  {{ calcState(item.orderType, item.state) }}
                </div>
              </div>
              <div class="con-bottom">
                <span class="over-ex">{{item.latestMsg}}</span>
                <span>{{ item.latestTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!list.length && empty"
          class="empty full-h">
          <img
            width="120"
            src="../../static/img/message-empty.png"/>
          <p>暂无订单</p>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import YbIm from '../../public/yb-im-sdk/src/index'
export default {
  props: {},
  inject: ['roomId'],
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      empty: false,
      error: false,
      query: {
        pageNo: 0,
        pageSize: 10,
      },
      imList: null,
    }
  },
  created() {
    try {
      this.imList = new YbIm({ autoSocket: false, env: 'prod' }) //autoSocket：进入聊天室，socket是否自动连接，非自动连接，需要在进入房间成功后调用connectSocket接口，env: dev, test or prod ,默认是prod
      console.log(this.imList)
      this.connect()
    } catch (e) {
      console.log(e)
    }
  },
  activated() {
    this.connect()
    this.onRefresh()
  },
  computed: {},
  methods: {
    getData() {
      this.empty = false
      this.query.pageNo++
      this.loading = true

      this.$axios({
        type: 'post',
        url: 'message/getServiceOrderMessage',
        data: this.query,
        elseData: {
          ajaxLoading: true,
          errorTip: false,
        },
      })
        .then((res) => {
          console.log(res)
          res.d.data || (res.d.data = [])
          this.list = [...this.list, ...res.d.data]

          this.list.forEach((item, index) => {
            if (item.unreadCount > 99)
              this.$set(this.list[index], 'unreadCount', '99+')
          })

          this.loading = false
          // 数据全部加载完成
          if (this.list.length >= res.d.total) {
            this.finished = true
          }
          if (!res.d.data.length) {
            this.empty = true
          }
        })
        .catch((e) => {
          this.error = true
        })
    },
    onRefresh() {
      // 清空列表数据
      this.empty = false
      this.query.pageNo = 1
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.$axios({
        type: 'post',
        url: 'message/getServiceOrderMessage',
        data: this.query,
        elseData: {
          ajaxLoading: true,
          errorTip: false,
        },
      })
        .then((res) => {
          console.log(res)
          res.d.data || (res.d.data = [])
          this.list = [...res.d.data]
          this.list.forEach((item, index) => {
            if (item.unreadCount > 99)
              this.$set(this.list[index], 'unreadCount', '99+')
            })
            this.loading = false
            this.refreshing = false
            // 数据全部加载完成
            if (this.list.length >= res.d.total) {
                this.finished = true
            }
            if (!res.d.data.length) {
                this.empty = true
            }
        })
        .catch(() => {
          this.error = true
          this.loading = false
        })
    },
    toImRoom(item) {
      if (item.orderType === 1) {
        this.$router.push({
          name: 'im.chat',
          query: {
            doctorAccountId: item.doctorAccountId,
            state: item.state,
            orderid: item.id,
            roomId: item.roomId,
            patientAccountId: this.$route.query.patientAccountId,
            orderType: item.orderType,
          },
        })
      } else {
        this.$router.push({
          name: 'im.chat',
          query: {
            doctorAccountId: item.doctorAccountId,
            state: item.state,
            orderid: item.id,
            roomId: item.roomId,
            payexpiretime: item.payExpireTime,
            patientAccountId: this.$route.query.patientAccountId,
            orderType: item.orderType,
          },
        })
      }
      this.resetPatientUnReadCount(item)
    },
    resetPatientUnReadCount(item) {
      this.$axios({
        type: 'post',
        url: 'message/resetPatientUnReadCount',
        data: {
          roomId: item.roomId,
        },
      }).then((res) => {
        // localStorage.setItem('pageNo', this.query.pageNo)
      })
    },
    refreshList() {
      this.onRefresh()
    },
    calcState(orderType, state) {
      if (orderType === 1) {
        if (state === 2) {
          return '随访中'
        } else if (state === 3) {
          return '已随访'
        }
      } else {
        if (state === 1) {
          return '待支付'
        } else if (state === 2) {
          return '新建'
        } else if (state === 3) {
          return '服务中'
        } else if (state === 4) {
          return '已完成'
        } else if (state === 5) {
          return '已取消'
        } else if (state === 6) {
          return '退款中'
        } else if (state === 7) {
          return '退款成功'
        } else if (state === 8) {
          return '退款失败'
        }
      }
    },
    countColor(orderType, state) {
      if (orderType === 1) {
        if (state === 2) {
          return '#FF8200'
        } else if (state === 3) {
          return '#1AB370'
        }
      } else {
        if (state === 1) {
          // 待支付
          return '#FF4D4F'
        } else if (state === 2) {
          // 新建
          return '#1890FF'
        } else if (state === 3) {
          // 服务中
          return '#1AB370'
        } else if (state === 4) {
          // 已完成
          return '#1AB370'
        } else if (state === 5) {
          // 已取消
          return '#999999'
        } else if (state === 6) {
          // 退款中
          return '#FF8200'
        } else if (state === 7) {
          // 退款成功
          return '#1AB370'
        } else if (state === 8) {
          // 退款失败
          return '#FF4D4F'
        }
      }
    },
    connect() {
      console.log(this.imList)
      this.imList
        .enterRoom({
          roomID: this.roomId,
          avatar: '',
          pwd: `room-${this.roomId}`,
        })
        .then((data) => {
          console.log('join room, get token')
          this.imList.connectSocket(this.roomId)
          // 收到文本消息回调
          this.imList.on(YbIm.EVENT.TEXT_MESSAGE_RECEIVED, (data) => {
            console.log(data)
          })

          // 收到用户进出房间提示消息回调
          this.imList.on(YbIm.EVENT.USER_TIPS, (data) => {
            console.log(data)
          })
          //收到socket连接失败回调
          this.imList.on(YbIm.EVENT.SOCKET_CONNECT_FAIL, (data) => {
            console.log(data)
          })
          //收到管理员群发消息回调
          this.imList.on(
            YbIm.EVENT.MANAGER_GROUP_SEND_MESSAGE_RECEIVED,
            (data) => {
              // console.log(data.text)
              this.handleSocketMsg(data)
            }
          )

          //用户加入房间回调
          this.imList.on(YbIm.EVENT.USER_JOIN, (data) => {
            console.log(data)
          })
        })
        .catch((error) => {
          console.log('join room fail')
          console.log(error)
          // alert(error)
          if (error.code === 10003) {
            console.log('直播间不存在')
          }
        })
    },
    handleSocketMsg(data) {
      console.log(JSON.parse(data.text))
      // if (JSON.parse(data.text).type !== 2) return
      this.list.forEach((item, index) => {
        if (JSON.parse(data.text).roomId === item.roomId) {
          this.$set(
            this.list[index],'latestTime',JSON.parse(data.text).latestTime)
          this.$set(
            this.list[index],
            'latestMsg',
            JSON.parse(data.text).latestMsg
          )
          this.$set(this.list[index], 'state', JSON.parse(data.text).state)
          if (JSON.parse(data.text).unreadCount <= 99) {
            this.$set(this.list[index],'unreadCount',JSON.parse(data.text).unreadCount
            )
          } else {
            this.$set(this.list[index], 'unreadCount', '99+')
          }
          // JSON.parse(data.text).unreadCount > 99
          //   ? this.$set(
          //       this.list[index],
          //       'unreadCount',
          //       JSON.parse(data.text).unreadCount
          //     )
          //   : this.$set(this.list[index], 'unreadCount', '99+')
          // if (item.unreadCount > 99)
          //   this.$set(this.list[index], 'unreadCount', '99+')
          // this.$set(this.list[index], 'unreadCount', item.unreadCount + 1)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  .van-pull-refresh {
    overflow: auto;
    height: 100%;
  }
  // /deep/.van-pull-refresh__track {
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   .van-list {
  //     height: 100%;
  //   }
  // }

  .chat-item {
    /* height: 140rpx; */
    margin:8px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 11px;
      position: relative;
      img {
        overflow: hidden;
        border-radius: 50%;
        margin-right: 12px;
      }
      span {
        display: block;
        position: absolute;
        min-width: 19px;
        height: 19px;
        background: #ff4d4f;
        top: -9px;
        left: 26px;
        border-radius: 10px;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        transform: scale(0.8);
        padding: 1px 4px;
        box-sizing: border-box;
      }
    }
    .chat-con {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      flex: 1;
      .con-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .name,
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-right: 10px;
        }
        .tag {
          padding: 2px 4px;
          border-radius: 2px;
          color: #ff8200;
          font-size: 12px;
          transform: scale(0.9);
          text-align: center;
          line-height: 16px;
        }
        .tag.type1 {
          background: rgba(24, 144, 255, 0.1);
          color: #1890ff;
        }
        .tag.type2 {
          background: rgba(26, 179, 112, 0.1);
          color: #1ab370;
        }
        .state {
          // position: absolute;
          font-size: 12px;
          font-weight: 400;
          // right: 12px;
          // top: 16px;
        }
      }
    }
    .con-bottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      height: 18px;
      align-items: center;
      .over-ex {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 200px;
      }
    }
  }

  .bottom {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: #333333;
    font-size: 12px;
  }

  .loading {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    color: #333333;
    font-size: 12px;
  }

  .intro {
    margin: 30px;
    text-align: center;
  }

  .trans {
    transition: 0.2s;
  }

  .empty {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    p {
      font-size: 14px;
    }
    img {
      margin-top: calc(50% - 50px);
    }
    color: #999;
  }
}
</style>
